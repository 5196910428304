/* Home page */

import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

export default function Home(props) {
  const ctx = props.pageContext;
  const sitedata = props.data.siteYaml;
  sitedata.language = ctx.locale;

  return (
    <Layout config={sitedata} ctx={ctx} currentpath={props.path}>
      <Helmet htmlAttributes={{lang: ctx.locale}}>
        <meta charSet="utf-8" />
        <title>{sitedata.sitename}</title>
        <link rel="canonical" href={sitedata.siteurl} />
        <meta name="description" content={sitedata.metaDescription}></meta>
      </Helmet>
    </Layout>
  );
};

export const query = graphql`
  query siteLocaleQ($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      locale
      variation
      homelink
      metaDescription
      sitename
      siteurl
      startstudy
      subtitle
      title
      surveyprompt
      banner {
        theme
        btntheme
      }
      fixedpanel {
        cta
        btntheme
      }
      navbar {
        theme
      }
      sections {
        id
        linkto
        text
        data {
          content
          items
        }
        title
        header
        subhead
        theme
      }
      patientFields {
        address
        cta
        ctaDq
        email
        firstname
        lastname
        phone
        referredbyDefault
        referredby {
          id
          value
        }
      }
      footer {
        links {
          label
          to
        }
        text
        theme
      }
      errorpage {
        header
        subheader
      }
      screener {
        questions {
          proceedIfYes
          q
          altBtn
        }
        questionLabel {
          affirm
          deny
          noun
          prep
        }
        instructions
        successResult {
          header
          message
          theme
          thankyou {
            locationpicked {
              theme
              header
              text
            }
            nolocation {
              theme
              header
              text
            }
          }
        }
        failResult {
          header
          message
          theme
          thankyou {
            theme
            header
            text
          }
        }
        locations {
          addresses {
            city
            lat
            lng
            name
            state
            street
            zip
          }
          cta
          ctabutton
          header
          noSuitableSite
        }
      }
    }
  }`;
